<app-bookshelf-template>
  <ng-template bookshelfTemplateTitle>
    <ng-template #listTitle>
      <div class="d-flex flex-nowrap px-1 bookmarks-title" data-automation-id="bookmarks-title">
        <div class="text-truncate d-block">
          {{ openedList.type === listType.forLater ? ('forLater' | translate) : (openedList.name) }}
        </div>
        &nbsp;
        <div class="w-auto">| {{ openedList.itemsCount }}</div>
      </div>
    </ng-template>
    <button class="bookmark__header__button bookmark__header__button--title w-100"
            data-automation-id="bookmarks-header-button"
            *ngIf="lists.length > 1; else listTitle"
            (click)="closeList.emit()">
      <fa-icon class="arrow-icon" [icon]="arrowIconLeft"></fa-icon>
      <ng-container *ngTemplateOutlet="listTitle"></ng-container>
    </button>
  </ng-template>

  <!-- View/Create Showcase (auth ff OFF) -->
  <ng-container *featureToggle="'licenseCustomShowcases'">
    <ng-container *ngIf="!isAuthPatronFlagEnabled">
      <ng-template bookshelfTemplateAction *ngIf="openedList.type === listType.regular">
        <button *ngIf="!openedList.showcaseRef && (hasCreateShowcasePermission$ | async)"
                class="bookmark__header__button"
                data-automation-id="bookmarks-action-button-create-showcase"
                (click)="createShowcase()">
          <fa-icon [icon]="showcaseIcon" [classes]="['icon showcase-icon']"></fa-icon>
          <span class="text" translate>bookmarksCreateShowcase</span>
        </button>
        <button *ngIf="openedList.showcaseRef && (hasViewShowcasePermission$ | async)"
                class="bookmark__header__button"
                data-automation-id="bookmarks-action-button-view-showcase"
                (click)="viewShowcase()">
          <fa-icon [icon]="showcaseIcon" [classes]="['icon showcase-icon']"></fa-icon>
          <span class="text" translate>bookmarksViewShowcase</span>
        </button>
      </ng-template>
    </ng-container>

    <!-- View/Create Showcase -->
    <ng-container *ngIf="isAuthPatronFlagEnabled">
      <ng-template bookshelfTemplateAction *ngIf="openedList.type === listType.regular">
        <ng-container *authorizationDirective="{
                permission: sitePermissions.SHOWCASE_CREATE
              }">
          <button *ngIf="!openedList.showcaseRef"
                  class="bookmark__header__button"
                  data-automation-id="bookmarks-action-button-create-showcase"
                  (click)="createShowcase()">
            <fa-icon [icon]="showcaseIcon" [classes]="['icon showcase-icon']"></fa-icon>
            <span class="text" translate>bookmarksCreateShowcase</span>
          </button>
        </ng-container>
        <ng-container *authorizationDirective="{
                permission: sitePermissions.SHOWCASE_VIEW
              }">
          <button *ngIf="openedList.showcaseRef"
                  class="bookmark__header__button"
                  data-automation-id="bookmarks-action-button-view-showcase"
                  (click)="viewShowcase()">
            <fa-icon [icon]="showcaseIcon" [classes]="['icon showcase-icon']"></fa-icon>
            <span class="text" translate>bookmarksViewShowcase</span>
          </button>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="openedList.items.length">
    <!-- Select All -->
    <ng-template bookshelfTemplateAction>
      <button class="bookmark__header__button"
              data-automation-id="bookmarks-action-button-toggle-select-all"
              (click)="setAllItemsSelection(openedList.id, !isAllSelected)"
              [attr.aria-pressed]="isAllSelected">
              <span class="icon" aria-hidden="true"
                    [ngClass]="isAllSelected ? 'fas fa-check-square' :  'far fa-square'"></span>
        <span translate class="text">bookmarksSelectAll</span>
      </button>
    </ng-template>

    <!-- Selection Counter -->
    <ng-template bookshelfTemplateAction>
      <div aria-live="polite" role="status"
           class="d-inline-flex align-items-center h-100 p-2"
           data-automation-id="bookmarks-selection-counter">
        <strong class="text-nowrap">
          {{ 'bookmarksItemsCountSelected' | translate: {itemsCount: itemsSelectedCount} }}:
        </strong>
      </div>
    </ng-template>

    <!-- Place Holds -->
    <ng-container *customerFeatureToggle="CustomerFeature.BulkHolds">
      <ng-template bookshelfTemplateAction>
        <button class="bookmark__header__button"
                data-automation-id="bookmarks-action-button-place-holds"
                [disabled]="!itemsSelectedCount"
                (click)="bulkPlaceHolds($event)">
          <fa-icon [icon]="placeHoldsIcon" [classes]="['icon place-holds-icon mr-1']"></fa-icon>
          <span translate class="text">bookmarksPlaceHolds</span>
        </button>
      </ng-template>
    </ng-container>

    <!-- Add To Lists -->
    <ng-template *ngIf="authorized" bookshelfTemplateAction>
      <app-select-list-button [buttonClass]="'bookmark__header__button'"
                              [lists]="lists"
                              [listItemEntities]="itemsEntitySelected"
                              [listIdsSelected]="[openedList.id]"
                              [disabled]="!itemsSelectedCount"
                              [multiselect]="true"
                              [canCreateNew]="true"
                              [confirmDelete]="true"
                              data-automation-id="bookmarks-action-add-to-list">
        <span class="fas fa-folder-plus icon d-inline-flex" aria-hidden="true"></span>
        <span class="text" translate>bookmarksAddToLists</span>
      </app-select-list-button>
    </ng-template>

    <!-- Remove From List -->
    <ng-template bookshelfTemplateAction>
      <app-bookshelf-remove-selected-from-list-button [itemsSelectedCount]="itemsSelectedCount"
                                                      [buttonClass]="'bookmark__header__button'"
                                                      (remove)="removeSelectedFromList()">
      </app-bookshelf-remove-selected-from-list-button>
    </ng-template>
  </ng-container>

  <!-- Items List -->
  <ng-template bookshelfTemplateBody="ps-short" let-psScroll="psScroll">
    <div
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollDisabled]="!psScroll || !authorized || allListItemsLoaded"
      [infiniteScrollThrottle]="50"
      (scrolled)="loadMoreShowcaseItems()"
      [infiniteScrollContainer]="psScroll">
      <app-searchable-bookmarks-search-bar
        (onSearch)="onSearch($event)"
      ></app-searchable-bookmarks-search-bar>
      <app-bookshelf-items-list
        [bookshelfItems]="openedList.items"
        [listId]="openedList.id"
        [ariaLabel]="'bookmarksListItemsListTitle' | translate"
        (onToggleItem)="onToggleItem($event)">
      </app-bookshelf-items-list>
    </div>
  </ng-template>
</app-bookshelf-template>
