import { FacetResult, ResponseFacet } from 'search/facets/models/resource-facet';
import { SearchType } from 'search/models/search-type';
import { Entity, EntityTypes, FormatGroup, PersonalList, TabEntity } from '../../entity/models/entity';
import { BookCover, SearchRequestBody } from 'search/models/search-results';
import { EditionData } from '../../citation/models/editions';
import { ListItemsLoadingState } from '../reducers/list.reducer';
import { BookmarkButtonState } from './bookmark-button-state';
import { ListPagination, ListPaginationParams } from './list.dto';

export interface ListItem {
  id: string;
  selected: boolean;
  entity: ListItemEntity;
}

export interface ListItemEntity {
  coverConfig?: BookCover;
  isUnresolved? : boolean;
  editionsData?: EditionData[];
  entityType: EntityTypes;
  id: string;
  title: string;
  checkoutDate?: string;
  readingHistoryItemId?: string;
  format?: string;
  primaryAgent?: TabEntity;
  personalLists?: PersonalList[]
  selectedTabRecordId?: string;
  bookmarkedRecordId?: string[];
  materialType?: string;
  sourceEntity: FormatGroup | Entity;
}

export enum ListType {
  forLater = 'FOR_LATER',
  regular = 'REGULAR',
}

interface ListCommonFields {
  id: string;
  name: string;
  pagination: ListPagination;
  items: ListItem[];
  itemsLoadingState: ListItemsLoadingState;
  sort: ListSort;
  showcaseRef?: string;
}

interface WithItemsCount {
  itemsCount: number;
}

export interface ForLaterList extends ListCommonFields {
  type: ListType.forLater;
}

export interface RegularList extends ListCommonFields {
  type: ListType.regular;
}

export type ForLaterListWithItemsCount = ForLaterList & WithItemsCount;
export type RegularListWithItemsCount = RegularList & WithItemsCount;

export type List = ForLaterList | RegularList;
export type ListWithItemsCount = List & WithItemsCount;

export type ListSortOrder = 'asc' | 'desc';
export type ListSortField = 'title' | 'date';

export interface ListSort {
  field: ListSortField;
  order: ListSortOrder;
}

export interface LoadedListItems {
  entities: ListItemEntity[];
  pagination: ListPagination;
}

export type LoadedList = Omit<List, 'items' | 'itemsLoadingState'> & LoadedListItems;

export interface UpdateListError {
  status: number;
  message: string;
}

export type GetListItemsError = UpdateListError;
export type PatchListItemsError = UpdateListError;
export type DeleteListError = UpdateListError;
export type CreateListError = UpdateListError;
export type DeleteListWithShowcaseError = {
  list?: DeleteListError,
  // property showcase is not set when there was no showcase linked to list, so no showcase was deleted
  showcase?: boolean,
};

export type ListError = CreateListError | UpdateListError | PatchListItemsError | DeleteListError;

export interface ListCreate {
  id: string;
}

export interface ListUpdate {
  id: string;
  name?: string;
}

export interface BookmarkedStatus {
  entityId: string;
  personalLists: PersonalList[];
  bookmarkButtonState: BookmarkButtonState;
  justAddedListId?: string;
}

export interface LoadListItemsPayload {
  id: string;
  sort: ListSort;
  paginationParams: ListPaginationParams;
  clearBeforeAdd?: true;
  notifyShowcaseId?: string;
  filterHiddenShowcaseItems?: boolean;
}

export interface GetSavedListError {
  status: number;
  message: string;
}

export enum SearchAvailabilityFilter {
  All = 'All',
  Available = 'Available'
}

export enum BookmarksSearchType {
  Format = 'format',
  Language = 'language',
  Location = 'location',
  Title = 'title',
  Author = 'author',
  DateFrom = 'dateFrom',
  Series = 'series',
  Concept = 'concept'
}

export interface BookmarksSearchFacets {
  format: FacetResult<ResponseFacet>,
  location: FacetResult<ResponseFacet>,
  language: FacetResult<ResponseFacet>
}

export const BookmarksSearchTypes: {id: BookmarksSearchType, label: string}[] = [
  {
    id: BookmarksSearchType.Format,
    label: 'Format'
  },
  {
    id: BookmarksSearchType.Language,
    label: 'Language'
  },
  {
    id: BookmarksSearchType.Location,
    label: 'Location'
  },
  {
    id: BookmarksSearchType.Title,
    label: 'Title'
  },
  {
    id: BookmarksSearchType.Author,
    label: 'Author'
  },
  {
    id: BookmarksSearchType.DateFrom,
    label: 'Publication Date'
  },
  {
    id: BookmarksSearchType.Series,
    label: 'Series'
  },
  {
    id: BookmarksSearchType.Concept,
    label: 'Concept'
  },
];

export const PAGE_SIZE = 50;

export const DEFAULT_BOOKMARKS_SEARCH = {
  searchText: '*',
  searchType: SearchType.AGENT,
  universalLimiterIds: [],
  resourceType: EntityTypes.FORMAT_GROUP,
  pageNum: 0,
  pageSize: PAGE_SIZE,
} as SearchRequestBody;

export const DEFAULT_AVAILABLE_BOOKMARKS_SEARCH = {
  ...DEFAULT_BOOKMARKS_SEARCH,
  universalLimiterIds: ['at_library', 'online']
} as SearchRequestBody;
