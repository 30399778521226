<div class="availability-card" [class.minimized]="isMinimized" [class.collapsed]="isCollapsed">
  <div class="header-actions-container">
    <div class="minimize-icon" (click)="toggleMinimize()">
      <span *ngIf="isMinimized">
        <div>
          <fa-icon [icon]="minimizeIcon" [classes]="['icon place-holds-icon mr-1']"></fa-icon>
          <span translate>checkAvailableNow</span>
          <img class="icon-online" src="../../../../assets/icon-online.svg" aria-hidden="true" />
        </div>
      </span>
      <div *ngIf="!isMinimized">
        <fa-icon [icon]="minimizeIcon" [classes]="['icon place-holds-icon mr-1']"></fa-icon>
        <span translate>minimize</span>
      </div>
    </div>
    <div class="minimize-icon" (click)="toggleCollapsed()">
      <fa-icon [icon]="closeIcon" [classes]="['icon place-holds-icon mr-1 x-icon']"></fa-icon>
    </div>
  </div>
  <div class="title-container">
    <div class="label-container">
      <p class="label" translate>bookmarkItemsAvailable</p>
      <img class="icon-online" src="../../../../assets/icon-online.svg" aria-hidden="true"/>
    </div>
    <div class="link-container">
      <a class="link" (click)="openList.emit()" translate>viewAllAvailableBookmarks<fa-icon [icon]="linkIcon" [classes]="['icon place-holds-icon mr-1']"></fa-icon>
      </a>
    </div>
  </div>
  <div class="options">
    <!-- Select All -->
    <button class="options__select-all"
            data-automation-id="bookmarks-search-preview-action-button-toggle-select-all"
            (click)="setAllItemsSelection(!isAllSelected)"
            [attr.aria-pressed]="isAllSelected">
            <span translate class="mr-1">bookmarksSelectAll</span>
            <span class="icon" aria-hidden="true"
                  [ngClass]="isAllSelected ? 'fas fa-check-square' :  'far fa-square'"></span>
    </button>

    <!-- Selection Counter -->
    <div aria-live="polite" role="status"
         class="d-inline-flex align-items-center h-100 p-2"
         data-automation-id="bookmarks-search-preview-selection-counter">
      <strong class="text-nowrap">
        {{ 'bookmarksItemsCountSelected' | translate: {itemsCount: itemsSelectedCount} }}:
      </strong>
    </div>

    <!-- Place Holds -->
    <ng-container *customerFeatureToggle="CustomerFeature.BulkHolds">
      <button class="options__place-hold"
              data-automation-id="bookmarks-search-preview-action-button-place-holds"
              [disabled]="!itemsSelectedCount"
              (click)="bulkPlaceHolds($event)">
        <!-- <fa-icon [icon]="placeHoldsIcon" [classes]="['icon place-holds-icon mr-1']"></fa-icon> -->
        <span translate class="text">bookmarksPlaceHolds</span>
      </button>
    </ng-container>
  </div>

  <div class="items-container">
    <div class="item" *ngFor="let item of preview; index as i; last as isLast">
      <div class="mb-1" *ngIf="true">
        <ins-bs-checkbox
          data-automation-id="bookmarks-search-preview-bulk-select-checkbox"
          (change)="onItemSelectChange(item.id, $event.checked)"
          [checked]="item.selected"
          [ariaLabelledby]="ariaTitleId"
          [containerClass]="['mr-3']">
        </ins-bs-checkbox>
      </div>
      <div class="cover-wrapper">
        <div class="cover-container">
          <app-cover
            class="image-container"
            [config]="item.entity.coverConfig"
            [title]="item.entity.title"
            [author]="item.entity.sourceEntity.primaryAgent?.label"
            data-automation-id="bookmarks-search-preview-card-image"
            size="large">
          </app-cover>
        </div>
        <div class="details">
          <p class="title">{{ item.entity.title }}</p>
          <p class="author">{{ item.entity.sourceEntity.primaryAgent?.label }}</p>
        </div>
        <div class="availability-container">
          <div class="availability-icon" *ngFor="let icon of item.icons">
            <button 
            mat-icon-button 
            matTooltip={{icon.formatGroup}}
            class="icon-button"
            >
              <img src="{{ icon.url }}" [style.filter]="icon.filter" alt="{{ icon.label }}"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
