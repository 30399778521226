import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concat, forkJoin, Observable, of, Subject, takeWhile } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FacetResult, ResponseFacet } from 'search/facets/models/resource-facet';
import { FacetsService } from 'search/facets/services/facets.service';
import { ResourceFacetBlock } from 'search/models/filter-panel';
import { FacetSearchObject } from 'search/models/search-object';
import { RollupResultsV2, SearchRequestBody } from 'search/models/search-results';
import { EntityTypes, FormatGroup } from '../../entity/models/entity';
import { LibraryList } from '../../library-list/reducers/library-list.reducer';
import {
  ListCreate,
  ListItem,
  ListItemEntity,
  ListSortField,
  ListType,
  ListUpdate,
  ListWithItemsCount,
  PAGE_SIZE,
} from '../models/list';
import {
  ListItemDto,
  ListItemsDto,
  ListPaginationParams,
  ListPatchItemsRequest,
  ListPatchItemsResponse,
  ListSortByString
} from '../models/list.dto';

export type ListItemsDtoWithoutSortBy = Omit<ListItemsDto, 'sortedBy'>;

@Injectable()
export class ListService {
  private static readonly listUrl = 'api/search-result/personalization/lists';
  private static readonly listSearchUrl = 'api/search-result/search/lists';

  constructor(
    private readonly http: HttpClient,
    private facetsService: FacetsService,
  ) {
  }

  public createList(name: string, items: ListItemDto[] = []): Observable<ListCreate> {
    return this.http
    .post<{ id: string }>(ListService.listUrl, {name, items}, {headers: { 'api-version': '2' }})
    .pipe(map(({id}) => ({id})));
  }

  public updateList(request: ListUpdate): Observable<ListUpdate> {
    return this.http
    .patch<void>(`${ListService.listUrl}/${request.id}/rename`, request, {headers: { 'api-version': '2' }})
    .pipe(map(() => request));
  }

  public getItems(
    id: string,
    sortBy: ListSortByString,
    paginationParams: ListPaginationParams,
    filterHiddenShowcaseItems = false,
  ): Observable<ListItemsDtoWithoutSortBy> {
    return this.http.get<ListItemsDtoWithoutSortBy>(`${ListService.listUrl}/${id}/items`, {
      params: {
        sortBy,
        pageNum: paginationParams.pageNum.toString(),
        pageSize: paginationParams.pageSize.toString(),
        filterHiddenShowcaseItems,
      },
      headers: { 'api-version': '2' }
    });
  }

  public patchItems(body: ListPatchItemsRequest): Observable<ListPatchItemsResponse> {
    if (!body.addTo.length && !body.deleteFrom.length) {
      return of({addedTo: [], deletedFrom: [], notFound: []});
    }
    return this.http.patch<ListPatchItemsResponse>(`${ListService.listUrl}/items`, body, {
      headers: { 'api-version': '2' }
    });
  }

  public makePatchItem(entity: ListItemEntity): ListItemDto {
    return {
      id: entity.id,
      entityType: entity.entityType,
      title: entity.title,
      recordId: entity.selectedTabRecordId,
    };
  }

  public deleteList(id: string): Observable<string> {
    return this.http
    .delete<void>(`${ListService.listUrl}/${id}`, {headers: { 'api-version': '2' }})
    .pipe(map(() => id));
  }

  public getList(id: string): Observable<LibraryList> {
    return this.http.get<LibraryList>(`${ListService.listUrl}/${id}`, {headers: {'api-version': '2'}});
  }

  public searchList(list: ListWithItemsCount, query: SearchRequestBody): Observable<ListWithItemsCount> {
    if (list.pagination.totalPages == 0 || list.pagination.page >= list.pagination.totalPages) {
      return of(list);
    }

    let itemsMatched = 0;
    const res = new Subject<ListWithItemsCount>();
    const requests: Observable<RollupResultsV2>[] = [];

    const emptyResult: RollupResultsV2 = {
      totalResults: 0,
      data: [],
      totalPages: 0
    };

    for (let pageNum = list.pagination.page; pageNum < list.pagination.totalPages; pageNum++) {
      requests.push(this.searchListPage(list.id, query, pageNum, PAGE_SIZE).pipe(
        catchError(() => of(emptyResult))
      ));
    }

    concat(...requests).pipe(takeWhile(response => {
      const items = response.data.map(this.fgToListItem);
      itemsMatched += items.length;
      list.pagination.page++;
      list.items.push(...items);

      if (itemsMatched >= PAGE_SIZE || list.pagination.page >= list.pagination.totalPages) {
        res.next(list);
        res.complete();
        return false;
      }
      return true;
    })).subscribe();

    return res.asObservable();
  }

  private searchListPage(id: string, searchRequestBody: SearchRequestBody, pageNum: number, pageSize: number): Observable<RollupResultsV2> {
    const options = {
      headers: {'api-version': '1'},
      params: {
        pageNum,
        pageSize,
      }
    };
    return this.http.post<RollupResultsV2>(`${ListService.listSearchUrl}/${id}`, searchRequestBody, options);
  }

  private fgToListItem(fg: FormatGroup): ListItem {
    const selectedTabRecordId = fg.materialTabs?.[0].editions?.[0].recordId;
    const item = {id: fg.id,
      selected: false,
      entity: {
        id: fg.id,
        coverConfig: {
          type: EntityTypes.FORMAT_GROUP,
          coverUrl: fg.coverUrl,
        },
        title: fg.title,
        sourceEntity: fg,
        entityType: EntityTypes.FORMAT_GROUP,
        selectedTabRecordId,
      }
    };
    return item;
  }

  public getFacets(): Observable<FacetResult<ResponseFacet>[]> {
    return forkJoin(this.getFormats(), this.getLocations(), this.getLanguages());
  }

  public getFormats(): Observable<FacetResult<ResponseFacet>> {
    const query: FacetSearchObject = {
      facetKey: ResourceFacetBlock.FORMATS,
      facetMapField: 'MaterialType',
    };
    const options = {
      pageNum: 0,
      pageSize: 100
    };
    return this.facetsService.getSingleFacet(query, options);
  }

  public getLocations(): Observable<FacetResult<ResponseFacet>> {
    const query: FacetSearchObject = {
      facetKey: ResourceFacetBlock.LOCATION,
      facetMapField: 'Location',
    };
    const options = {
      pageNum: 0,
      pageSize: 100
    };
    return this.facetsService.getSingleFacet(query, options);
  }

  public getLanguages(): Observable<FacetResult<ResponseFacet>> {
    const query: FacetSearchObject = {
      facetKey: ResourceFacetBlock.LANGUAGE,
      facetMapField: 'Language',
    };
    const options = {
      pageNum: 0,
      pageSize: 100
    };
    return this.facetsService.getSingleFacet(query, options);
  }
}
