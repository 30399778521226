import { createAction, props } from '@ngrx/store';
import { SearchObject } from 'search/models/search-object';
import { RollupResultsV2 } from 'search/models/search-results';
import {
  DeleteSavedSearchWithShowcaseError,
  GetSavedSearchError,
  GetSavedSearchResponse,
  LoadSavedSearchesError,
  RunSavedSearchError,
  SavedSearch,
  SavedSearchUpdate,
  SaveSearchError,
  SaveSearchRequest,
  UpdateSavedSearchError,
} from '../models/saved-search';

export const tryToOpenSaveSearchModal = createAction(
  '[SavedSearch] Try to open save search modal',
);

export const openSaveSearchModal = createAction(
  '[SavedSearch] Open save search modal',
);

export const createSavedSearch = createAction(
  '[SavedSearch] Create saved search',
  props<{ request: SaveSearchRequest }>(),
);

export const createSavedSearchComplete = createAction(
  '[SavedSearch] Create saved search complete',
  props<{ savedSearch: SavedSearch }>(),
);

export const createSavedSearchFailure = createAction(
  '[SavedSearch] Create saved search failure',
  props<{ error: SaveSearchError }>(),
);

export const resetSavedSearchCreatedState = createAction(
  '[SavedSearch] Reset saved search created state',
);

export const updateSavedSearch = createAction(
  '[SavedSearch] Update saved search',
  props<{ request: SavedSearchUpdate }>(),
);

export const updateSavedSearchComplete = createAction(
  '[SavedSearch] Update saved search complete',
  props<{ savedSearchUpdate: SavedSearchUpdate }>(),
);

export const updateSavedSearchFailure = createAction(
  '[SavedSearch] Update saved search failure',
  props<{ id: string, error: UpdateSavedSearchError }>(),
);

export const resetSavedSearchUpdatedState = createAction(
  '[SavedSearch] Reset saved search update state',
);

export const loadSavedSearches = createAction(
  '[SavedSearch] Load saved searches',
);

export const loadSavedSearchesComplete = createAction(
  '[SavedSearch] Load saved searches complete',
  props<{ savedSearches: SavedSearch[] }>(),
);

export const loadSavedSearchesFailure = createAction(
  '[SavedSearch] Load saved searches failure',
  props<{ error: LoadSavedSearchesError }>(),
);

export const resetSavedSearchLoadedState = createAction(
  '[SavedSearch] Reset saved search loaded state',
);

export const deleteSavedSearch = createAction(
  '[SavedSearch] Delete saved search',
  props<{ savedSearch: SavedSearch, showcaseRemoveResult?: 'success' | 'error' }>(),
);

export const deleteSavedSearchComplete = createAction(
  '[SavedSearch] Delete saved search complete',
  props<{ id: string }>(),
);

export const deleteSavedSearchFailure = createAction(
  '[SavedSearch] Delete saved search failure',
  props<{ id: string, error: DeleteSavedSearchWithShowcaseError }>(),
);

export const resetSavedSearchDeleteState = createAction(
  '[SavedSearch] Reset saved search delete state',
);

export const openEmailModal = createAction(
  '[SavedSearch] Open email modal',
  props<{ id: string }>(),
);

export const copySavedSearch = createAction(
  '[SavedSearch] Copy',
  props<{ id: string }>(),
);

export const shareOnFacebook = createAction(
  '[SavedSearch] Share on Facebook',
  props<{ id: string, title: string, image: string }>()
);

export const shareOnTwitter = createAction(
  '[SavedSearch] Share on Twitter',
  props<{ id: string, title: string, image: string }>()
);

export const redirectToSocialMediaSuccess = createAction(
  '[SavedSearch] Share on Twitter and Facebook Success',
);

export const writeUrl = createAction(
  '[SavedSearch] Write URL',
  props<{ id: string, url: string }>(),
);

export const setLastCopied = createAction(
  '[SavedSearch] Set last copied',
  props<{ id: string }>(),
);

export const getSavedSearch = createAction(
  '[SavedSearch] Get Saved Search',
  props<{ id: string }>(),
);

export const getSavedSearchComplete = createAction(
  '[SavedSearch] Get saved search complete',
  props<{ response: GetSavedSearchResponse, id: string, title?: string, image?: string }>(),
);

export const resetGetSavedSearch = createAction(
  '[SavedSearch] Reset get saved search',
);

export const getSavedSearchFailure = createAction(
  '[SavedSearch] Get saved search failure',
  props<{ id: string, error: GetSavedSearchError }>(),
);

export const focusAvailableCardOrEmptyResultMessage = createAction(
  '[SavedSearch] Focus available card or empty result message',
);

export const stopFocusAvailableCardOrEmptyResultMessage = createAction(
  '[SavedSearch] Stop focus card or empty result message',
);

export const focusShareButton = createAction(
  '[SavedSearch] Focus share button',
  props<{ savedSearchId: any }>(),
);

export const stopFocusShareButton = createAction(
  '[SavedSearch] Stop focus share button',
);

export const runSavedSearch = createAction(
  '[SavedSearch] Run saved search',
  props<{ savedSearchId: string }>(),
);

export const runSavedSearchResults = createAction(
  '[SavedSearch] Run saved search results',
  props<{ results: RollupResultsV2, searchObject: SearchObject }>(),
);

export const runSavedSearchFailure = createAction(
  '[SavedSearch] Run saved search failure',
  props<{ id: string, error: RunSavedSearchError }>(),
);

export const setSavedSearchShowcaseRef = createAction(
  '[SavedSearch] Set saved search showcase ref',
  props<{ savedSearchId: string, showcaseRef: string }>(),
);

export const unsetSavedSearchShowcaseRef = createAction(
  '[SavedSearch] Unset saved search showcase ref',
  props<{ savedSearchId: string }>(),
);

export const resetSavedSearchRunState = createAction(
  '[SavedSearch] Reset saved search run state',
);
