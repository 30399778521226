import { AvailabilityStatus } from '../entity/models/entity';

const MATERIAL_ICONS_BASE_URL = `${API_URL}/discover-admin-path/material-icons`;

export type MaterialIconDictionary = {
  [key: string]: {
    icon: string;
    label: string;
  }
}

export const MATERIAL_ICONS: MaterialIconDictionary = {
  'default-other': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/default-other.svg`,
    'label': 'Default other'
  },
  'activity-pass': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/activity-pass.svg`,
    'label': 'Activity pass'
  },
  'audiobook': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/audiobook.svg`,
    'label': 'Audiobook'
  },
  'blu-ray': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/blu-ray.svg`,
    'label': 'Blu-Ray'
  },
  'book': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/book.svg`,
    'label': 'Book'
  },
  'book-kit': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/book-kit.svg`,
    'label': 'Book kit'
  },
  'braille': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/braille.svg`,
    'label': 'Braille'
  },
  'dvd': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/dvd.svg`,
    'label': 'Dvd'
  },
  'e-gov-doc': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/e-gov-doc.svg`,
    'label': 'eGoverment document'
  },
  'e-resource': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/e-resource.svg`,
    'label': 'eResource'
  },
  'ebook': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/ebook.svg`,
    'label': 'eBook'
  },
  'equipment': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/equipment.svg`,
    'label': 'Equipment'
  },
  'game': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/game.svg`,
    'label': 'Game'
  },
  'gov-doc': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/gov-doc.svg`,
    'label': 'Goverment document'
  },
  'graphic-novel': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/graphic-novel.svg`,
    'label': 'Graphic Novel'
  },
  'laptop': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/laptop.svg`,
    'label': 'Laptop'
  },
  'large-type': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/large-type.svg`,
    'label': 'Large Type'
  },
  'magazine': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/magazine.svg`,
    'label': 'Magazine'
  },
  'manuscript': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/manuscript.svg`,
    'label': 'Manuscript'
  },
  'map': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/map.svg`,
    'label': 'Map'
  },
  'mobile-device': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/mobile-device.svg`,
    'label': 'Mobile Device'
  },
  'music-cassette': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/music-cassette.svg`,
    'label': 'Music Cassette'
  },
  'music-cd': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/music-cd.svg`,
    'label': 'Music CD'
  },
  'newspaper': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/newspaper.svg`,
    'label': 'Newspaper'
  },
  'nintendo': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/nintendo.svg`,
    'label': 'Nintendo'
  },
  'photograph': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/photograph.svg`,
    'label': 'Photograph'
  },
  'picture-book': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/picture-book.svg`,
    'label': 'Picture Book'
  },
  'playstation': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/playstation.svg`,
    'label': 'Playstation'
  },
  'puzzle': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/puzzle.svg`,
    'label': 'Puzzle'
  },
  'seeds': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/seeds.svg`,
    'label': 'Seeds'
  },
  'sheet-music': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/sheet-music.svg`,
    'label': 'Sheet music'
  },
  'software': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/software.svg`,
    'label': 'Software'
  },
  'stream-video': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/stream-video.svg`,
    'label': 'Stream video'
  },
  'streaming-music': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/streaming-music.svg`,
    'label': 'Streaming Music'
  },
  'talking-book': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/talking-book.svg`,
    'label': 'Talking Book'
  },
  'vhs': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/vhs.svg`,
    'label': 'VHS'
  },
  'video-game': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/video-game.svg`,
    'label': 'Video Game'
  },
  'vinyl': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/vinyl.svg`,
    'label': 'Vinyl'
  },
  'vox': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/vox.svg`,
    'label': 'Book + Audio'
  },
  'website': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/website.svg`,
    'label': 'Website'
  },
  'wifi': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/wifi.svg`,
    'label': 'Wifi'
  },
  'xbox': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/xbox.svg`,
    'label': 'Xbox'
  },
  'museum': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/museum.svg`,
    'label': 'Museum'
  },
  'toy': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/toy.svg`,
    'label': 'Toy'
  },
  'non-musical-recording': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/non-musical-recording.svg`,
    'label': 'Non Musical recording'
  },
  'videorecording': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/videorecording.svg`,
    'label': 'Videorecording'
  },
  'bake-equipment': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/bake-equipment.svg`,
    'label': 'Bake Equipment'
  },
  'equipment-kit': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/equipment-kit.svg`,
    'label': 'Equipment Kit'
  },
  'medical': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/medical.svg`,
    'label': 'Medical'
  },
  'microfilm': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/microfilm.svg`,
    'label': 'Microfilm'
  },
  'microform': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/microform.svg`,
    'label': 'Microform'
  },
  'atlas': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/atlas.svg`,
    'label': 'Atlas'
  },
  'e-audiobook': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/e-audiobook.svg`,
    'label': 'eAudiobook'
  },
  'e-graphic-novel': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/e-graphic-novel.svg`,
    'label': 'eGraphic Novel'
  },
  'e-magazine': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/e-magazine.svg`,
    'label': 'eMagazine'
  },
  'e-equipment': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/e-equipment.svg`,
    'label': 'eEquipment'
  },
  'e-game': {
    'icon': `${MATERIAL_ICONS_BASE_URL}/e-game.svg`,
    'label': 'eGame'
  }
};

const GREEN_FILTER = 'invert(26%) sepia(95%) saturate(1791%) hue-rotate(96deg) brightness(97%) contrast(104%)';
const GRAY_FILTER = 'invert(39%) sepia(0%) saturate(0%) hue-rotate(351deg) brightness(98%) contrast(90%)';
const RED_FILTER = 'invert(19%) sepia(97%) saturate(7498%) hue-rotate(28deg) brightness(98%) contrast(101%)';

export const AvailabilityStatusToFilterMap: {[key: string]: string} = {
  [AvailabilityStatus.AVAILABLE]: GREEN_FILTER,
  [AvailabilityStatus.CHECK_AVAILABILITY]: GRAY_FILTER,
  [AvailabilityStatus.CHECKED_OUT]: RED_FILTER,
  [AvailabilityStatus.UNAVAILABLE]: RED_FILTER,
};

export interface Icon {
  url: string,
  filter: string
}
