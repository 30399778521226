<app-bookshelf-template>
  <ng-template bookshelfTemplateTitle>
    <ng-template #listTitle>
      <div class="d-flex flex-nowrap px-1 bookmarks-title" data-automation-id="bookmarks-title">
        <span class="text-truncate d-block" translate>allAvailable</span>
        &nbsp;
        <div class="w-auto">| {{ itemsCount }}</div>
      </div>
    </ng-template>
    <button class="bookmark__header__button bookmark__header__button--title w-100"
            data-automation-id="bookmarks-header-button"
            *ngIf="lists.length > 1; else listTitle"
            (click)="closeList.emit()">
      <fa-icon class="arrow-icon" [icon]="arrowIconLeft"></fa-icon>
      <ng-container *ngTemplateOutlet="listTitle"></ng-container>
    </button>
  </ng-template>

  <ng-container *ngIf="list.length">
    <!-- Select All -->
    <ng-template bookshelfTemplateAction>
      <button class="bookmark__header__button"
              data-automation-id="bookmarks-action-button-toggle-select-all"
              (click)="setAllItemsSelection('available-list', !isAllSelected)"
              [attr.aria-pressed]="isAllSelected">
              <span class="icon" aria-hidden="true"
                    [ngClass]="isAllSelected ? 'fas fa-check-square' :  'far fa-square'"></span>
        <span translate class="text">bookmarksSelectAll</span>
      </button>
    </ng-template>

    <!-- Selection Counter -->
    <ng-template bookshelfTemplateAction>
      <div aria-live="polite" role="status"
           class="d-inline-flex align-items-center h-100 p-2"
           data-automation-id="bookmarks-selection-counter">
        <strong class="text-nowrap">
          {{ 'bookmarksItemsCountSelected' | translate: {itemsCount: itemsSelectedCount} }}:
        </strong>
      </div>
    </ng-template>

    <!-- Place Holds -->
    <ng-container *customerFeatureToggle="CustomerFeature.BulkHolds">
      <ng-template bookshelfTemplateAction>
        <button class="bookmark__header__button"
                data-automation-id="bookmarks-action-button-place-holds"
                [disabled]="!itemsSelectedCount"
                (click)="bulkPlaceHolds($event)">
          <fa-icon [icon]="placeHoldsIcon" [classes]="['icon place-holds-icon mr-1']"></fa-icon>
          <span translate class="text">bookmarksPlaceHolds</span>
        </button>
      </ng-template>
    </ng-container>
  </ng-container>

  <!-- Items List -->
  <ng-template bookshelfTemplateBody="ps-short" let-psScroll="psScroll">
    <div
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollDisabled]="!psScroll || !authorized"
      [infiniteScrollThrottle]="50"
      (scrolled)="loadMoreShowcaseItems()"
      [infiniteScrollContainer]="psScroll">
      <app-searchable-bookmarks-search-bar
        (onSearch)="onSearch($event)"
        [availabilityFilter]="availabilityFilter"
        [lockAvailabilityFilter]="true"
      ></app-searchable-bookmarks-search-bar>
      <app-bookshelf-items-list [bookshelfItems]="list"
                                [listId]="'available-list'"
                                [ariaLabel]="'bookmarksListItemsListTitle' | translate"
                                (onToggleItem)="onToggleItem('available-list', $event)">
      </app-bookshelf-items-list>
    </div>
  </ng-template>
  <span translate>allAvailable</span>
</app-bookshelf-template>
