import { ChangeDetectorRef, Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PickupArea } from 'app/models/locations';

@Component({
  selector: 'app-pickup-area-select',
  templateUrl: './pickup-area-select.component.html',
  styleUrls: ['./pickup-area-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PickupAreaSelectComponent),
    multi: true
  }]
})
export class PickupAreaSelectComponent implements ControlValueAccessor {
  @Input() public availablePickupArea: PickupArea[];
  @Input() public selectedPickupAreaCode: string;
  @Input() public dataAutomationId: string;
  public disabled: boolean;

  constructor(private cd: ChangeDetectorRef) {}
  private onChange: (value: string) => void;
  private onTouched: () => void;

  writeValue(value: string): void {
    this.selectedPickupAreaCode = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onPickupAreaChange(areaCode: string) {
    this.writeValue(areaCode);
    this.onChange(areaCode);
    this.onTouched();
  }
}
