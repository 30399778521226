import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faBookmark } from '@fortawesome/pro-regular-svg-icons';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, filter, Observable, Subscription } from 'rxjs';
import { SearchRequestBody } from 'search/models/search-results';
import { AvailabilityStatus, FormatGroup, MaterialTab } from '../../../entity/models/entity';
import { DictionaryItem, DictionaryTypes } from '../../../models/dictionaries';
import { AvailabilityStatusToFilterMap, MATERIAL_ICONS } from '../../../models/material-icons';
import { DictionariesService } from '../../../services/dictionaries.service';
import { loginFromBookmarksTab } from '../../actions/list.actions';
import { ListItem, ListWithItemsCount } from '../../models/list';
import { ListService } from '../../services/list.service';
import { Icon, ListItemWithIcons } from '../searchable-bookmarks-results-preview/searchable-bookmarks-results-preview.component';

@Component({
  selector: 'app-searchable-bookmarks-lists',
  templateUrl: './searchable-bookmarks-lists.component.html',
  styleUrls: ['./searchable-bookmarks-lists.component.scss']
})
export class SearchableBookmarksListsComponent {
  @Input() public lists: ListWithItemsCount[];
  @Input() public authorized: boolean;
  @Input() public isStaffAuthorized: boolean;
  @Output() public openList = new EventEmitter();
  @Output() public openAvailableList = new EventEmitter();
  public bookmarkIcon = faBookmark;
  public makeSearch = new BehaviorSubject<SearchRequestBody>(null);
  public preview: ListItem[];

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly listService: ListService,
    private readonly dictionariesService: DictionariesService
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(this.makeSearch.subscribe(search => {
      if (!search) return;
      this.applySearch(search);
    }));
    this.generateSearchPreview(this.lists);
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public logIn(event: Event): void {
    event.stopPropagation();
    this.store.dispatch(loginFromBookmarksTab());
  }

  public trackById(index: number, list: ListWithItemsCount): string {
    return list.id;
  }

  public onSearch(search: SearchRequestBody) {
    this.makeSearch.next(search);
  }

  private applySearch(search: SearchRequestBody) {
    for (const list of this.lists) {
      list.pagination.page = 0;
      list.items = [];
    }
    const obs: Observable<ListWithItemsCount>[] = [];
    for (const list of this.lists) {
      obs.push(this.listService.searchList(list, search));
    }
    return combineLatest(obs)
    .pipe(filter(Boolean)).subscribe(result => {
      this.lists = result;
      this.generateSearchPreview(this.lists);
    });
  }

  private getIcons(materialTabs: MaterialTab[]) {
    const icons: Icon[] = [];
    materialTabs?.slice(0, 4).forEach((m, index) => {
      const code = m.materialTypes?.[0];
      const item: DictionaryItem = this.dictionariesService.getDictionaryItemByCode(
        DictionaryTypes.MATERIAL_TYPES,
        code
      );
      const iconKey = materialTabs.length > 4 && index === 3
        ? 'default-other'
        : item?.icon ?? 'default-other';
      const iconDetails = MATERIAL_ICONS[iconKey as string];
      const url = iconDetails?.icon || '';
      const label = iconDetails?.label || '';
      const formatGroup = item?.formatGroup;
      const availabilityStatus = m.availability.status.general ?? AvailabilityStatus.UNAVAILABLE;
      const filter = AvailabilityStatusToFilterMap[availabilityStatus];
      icons.push({ url, filter, label, formatGroup });
    });
    return icons;
  }

  public generateSearchPreview(lists: ListWithItemsCount[]) {
    const preview: ListItem[] = [];
    const limit = 6;
    for (const list of lists) {
      if (preview.length >= limit) break;
      for (const item of list.items) {
        let hasAvailableMaterialType = false;
        const sourceEntity = item?.entity?.sourceEntity;
        if (!sourceEntity) continue;
        const materialTabs = (sourceEntity as FormatGroup).materialTabs;
        for (const materialTab of materialTabs) {
          const status = materialTab?.availability?.status?.general;
          if (!status) continue;

          if (status == AvailabilityStatus.AVAILABLE || status == AvailabilityStatus.CHECK_AVAILABILITY) {
            hasAvailableMaterialType = true;
            break;
          }
        }
        if (hasAvailableMaterialType) {
          preview.push(item);
          if (preview.length >= limit) break;
        }
      }
    }
    this.preview = preview.map(item => {
      (item as ListItemWithIcons).icons = this.getIcons((item.entity.sourceEntity as FormatGroup).materialTabs);
      return item;
    });
  }
}
