import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownValue } from 'common/models/custom-dropdown';
import { FacetResult, ResponseFacet } from 'search/facets/models/resource-facet';
import { SearchRequestBody } from 'search/models/search-results';
import { SearchType } from 'search/models/search-type';
import { EntityTypes } from '../../../entity/models/entity';
import {
  SearchAvailabilityFilter,
  BookmarksSearchType,
  BookmarksSearchTypes,
  BookmarksSearchFacets,
  PAGE_SIZE,
} from '../../models/list';
import { faObjectsColumn, faSquareCheck } from '@fortawesome/pro-light-svg-icons';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getSearchFacets } from '../../reducers/list.reducer';


@Component({
  selector: 'app-searchable-bookmarks-search-bar',
  templateUrl: './searchable-bookmarks-search-bar.component.html',
  styleUrls: ['./searchable-bookmarks-search-bar.component.scss']
})
export class SearchableBookmarksSearchBarComponent implements OnInit {
  @Input() public availabilityFilter: SearchAvailabilityFilter = SearchAvailabilityFilter.All;
  @Input() public lockAvailabilityFilter: boolean = false;
  @Output() public onSearch = new EventEmitter<SearchRequestBody>();
  public noMatchesFound: boolean = false;
  public searchTypes = BookmarksSearchTypes;
  public searchType: BookmarksSearchType = BookmarksSearchType.Author;
  protected readonly SearchAvailabilityFilter = SearchAvailabilityFilter;
  public searchText = '';
  public faObjectsColumn = faObjectsColumn;
  public faSquareCheck = faSquareCheck;
  public facets: BookmarksSearchFacets;

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(this.store.select(getSearchFacets).subscribe(facets => this.facets = facets));
  }

  public onKeydownEnter(value: string) {
    if (value === this.searchText) return;
    this.searchText = value;
    if (!this.checkMatches()) return;
    this.onSearch.emit(this.getSearchOptions());
  }

  public clearSearchText(inputElement: HTMLInputElement) {
    inputElement.value = '';
    this.searchText = '';
    this.noMatchesFound = false;
    this.onSearch.emit(this.getSearchOptions());
  }

  private getSearchOptions(): SearchRequestBody {
    const searchText = this.searchText != '' ? this.searchText : '*';
    let searchOptions: SearchRequestBody = {
      searchText: '*',
      searchType: SearchType.EVERYTHING,
      universalLimiterIds: this.availabilityFilter == SearchAvailabilityFilter.All ? [] : ['at_library', 'online'],
      resourceType: EntityTypes.FORMAT_GROUP,
      pageNum: 0,
      pageSize: PAGE_SIZE,
    } as SearchRequestBody;
    switch (this.searchType) {
      case BookmarksSearchType.Format:
        searchOptions.materialTypeIds = this.findMatchingIds(searchText, this.facets.format);
        break;
      case BookmarksSearchType.Language:
        searchOptions.languageIds = this.findMatchingIds(searchText, this.facets.language);
        break;
      case BookmarksSearchType.Location:
        searchOptions.locationIds = this.findMatchingIds(searchText, this.facets.location);
        break;
      case BookmarksSearchType.Title:
        searchOptions.searchText = searchText ?? '*';
        searchOptions.searchType = SearchType.TITLE;
        break;
      case BookmarksSearchType.Author:
        searchOptions.searchText = searchText ?? '*';
        searchOptions.searchType = SearchType.AGENT;
        break;
      case BookmarksSearchType.DateFrom:
        searchOptions.dateFrom = parseInt(searchText);
        break;
      case BookmarksSearchType.Series:
        searchOptions.searchText = searchText ?? '*';
        searchOptions.searchType = SearchType.SERIES;
        break;
      case BookmarksSearchType.Concept:
        searchOptions.searchText = searchText ?? '*';
        searchOptions.searchType = SearchType.CONCEPT;
        break;
    }

    return searchOptions;
  }

  public onSearchTypeChange(event: DropdownValue<BookmarksSearchType>) {
    this.searchType = event.selected;
    if (!this.checkMatches()) return;
  }

  public toggleAvailability(value: SearchAvailabilityFilter) {
    if (this.lockAvailabilityFilter) return;
    if (value == this.availabilityFilter) return;
    this.availabilityFilter = value;
    this.onSearch.emit(this.getSearchOptions());
  }

  private findMatchingIds(searchText: string, facet: FacetResult<ResponseFacet>) {
    const regEx = '/[^A-Z0-9]/ig';
    const text = searchText.toLowerCase().replace(regEx, '');
    const ids = facet.data.filter(value => value.label.toLowerCase().replace(regEx, '').includes(text)).map(value => value.id);
    return ids;
  }

  private checkMatches(): boolean {
    this.noMatchesFound = false;
    if (!this.searchText) return true;
    if ([BookmarksSearchType.Format, BookmarksSearchType.Location, BookmarksSearchType.Language].includes(this.searchType)) {
      const facet = this.facets[this.searchType as keyof BookmarksSearchFacets];
      const matches = this.findMatchingIds(this.searchText, facet);
      if (!matches.length) {
        this.noMatchesFound = true;
        return false;
      }
    }
    return true;
  }
}
