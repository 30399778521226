<div class="get-it-modal-dialog p-4" data-automation-id="get-it-modal-dialog">
  <app-modal-header
    title="{{'getThisItemDialogTitle' | translate}}"
    titleId="get-it-modal-title"
    closeId="close-get-it-dialog-btn"
    (close)="onClose()">
  </app-modal-header>
  <div class="modal-body">
    <div data-automation-id="get-it-requested-volume" *ngIf="volume">
      <div class="requested-label mb-2" translate>requestedVolume</div>
      <div data-automation-id="get-it-requested-volume-value">{{volume}}</div>
    </div>
    <div data-automation-id="get-it-requested-copy" *ngIf="itemVolume">
      <div class="requested-label mb-2" translate>requestedCopy</div>
      <div data-automation-id="get-it-requested-copy-value">{{itemVolume}}</div>
    </div>
    <form [formGroup]="getItForm" class="get-it-form" (ngSubmit)="onSubmit()">
      <app-pickup-location-select
        formControlName="pickupLocation"
        [pickupLocations]="pickupLocations"
        [selectedLocationCode]="selectedLocationCode"
        (codeChanging)="changePickupLocation($event)"
        [dataAutomationId]="'pickupLocationDropdown'">
      </app-pickup-location-select>
      <ng-container *ngIf="isPickupAreaFeatureEnabled && ilsType === IlsType.polaris">
        <app-pickup-area-select formControlName="pickupArea"
                                [availablePickupArea]="availablePickupArea"
                                [selectedPickupAreaCode]="getItForm.get('pickupArea')?.value"
                                [attr.aria-describedby]="'pickup-area-error'"
                                [dataAutomationId]="'pickupAreaDropdown'">
        </app-pickup-area-select>
        <div aria-atomic="true"
             aria-live="assertive"
             role="alert">
          <p *ngIf="!pickupArea.value && hasPickupAreaOptions && getItForm.get('pickupLocation')?.touched"
             id="pickup-area-error"
             class="error-message pickup-area-error-message">
              {{ 'pickupErrorMessage' | translate }}
          </p>
        </div>
      </ng-container>
      <ng-container *customerFeatureToggle="CustomerFeature.HoldBorrowByMail">
        <section *ngIf="borrowByMailConfiguration?.enabled">
          <ins-bs-checkbox
            [checked]="borrowByMail.value"
            [containerClass]="['my-3', 'mr-3']"
            (change)="onBorrowByMailChange($event.checked)"
            [aria-label]="'borrowByMailTitle' | translate"
            data-automation-id="borrow-by-mail-checkbox">
            <span class="borrow-by-mail__checkbox" translate>borrowByMailTitle</span>
          </ins-bs-checkbox>

          <app-borrow-by-mail-user-address
            *ngIf="borrowByMail.value"
            [userAddress]="userAddress">
          </app-borrow-by-mail-user-address>

          <div
            *ngIf="borrowByMail.value"
            data-automation-id="borrow-by-mail-message"
            class="d-flex align-items-center p-3 borrow-by-mail-message mt-3">
            <span class="fas fa-check-circle fa-4x check-icon" aria-hidden="true"></span>
            <span class="ml-2">{{borrowByMailConfiguration.deliveryChargeMessage}}</span>
          </div>
        </section>
      </ng-container>

      <app-hold-needed-date-form
        formGroupName="holdNeededDate"
        [holdActivationDateSetting]="holdActivationDateSetting"
        [holdExpirationDefault]="holdExpirationDefault"
        [ilsType]="ilsType">
      </app-hold-needed-date-form>

      <section class="actions mt-4">
        <button
          class="ins-buttons button-primary flint"
          type="submit"
          [disabled]="!pickupLocation.value || (!pickupArea.value && hasPickupAreaOptions) || (isLoading$ | async)"
          data-automation-id="confirm-get-it-btn"
          translate>
          confirmLocation
        </button>
        <button
          type="button"
          class="ins-buttons button-secondary flint"
          (click)="onClose()"
          [disabled]="(isLoading$ | async)"
          data-automation-id="cancel-get-it-btn"
          translate>
          cancelCapitalized
        </button>
      </section>
    </form>
  </div>
  <div aria-atomic="true" aria-live="assertive" role="alert">
    <p *ngIf="errorMessage"
         class="error-message py-2 px-3 text-center"
         data-automation-id="location-error">
         {{ errorMessage }}
    </p>
  </div>
  <div class="d-flex justify-content-center text-white" role="status" aria-live="polite">
    <div *ngIf="(isLoading$ | async)"
          class="hold-spinner-border spinner-border text-primary"
          data-automation-id="holds-loading">
      <span class="sr-only" translate>Loading...</span>
    </div>
  </div>
</div>
