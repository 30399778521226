import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faAngleLeft, faBookmark as bookmarkIcon } from '@fortawesome/pro-regular-svg-icons';
import { faCartShoppingFast, faConveyorBeltAlt } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngrx/store';
import { CustomerFeature } from 'app/customer-integration/customer-integration';
import { BehaviorSubject, filter, Subscription } from 'rxjs';
import { SearchRequestBody } from 'search/models/search-results';
import { openShowcasesTab } from 'user/actions/user-profile.actions';
import { UserPermission } from 'user/models/user';
import { doesUserHavePermission } from 'user/reducers/user.reducer';
import { tryToBulkHoldSelectedItems } from '../../../bulk-select/actions/bulk-select.actions';
import { BulkFeatureKey } from '../../../bulk-select/enums/bulk-feature-key';
import { hideForm, openShowcase, showForm } from '../../../custom-showcase/actions/custom-showcase.actions';
import { CustomShowcaseCreatedFromType } from '../../../custom-showcase/models/custom-showcase';
import { SitePermissions } from '../../../permissions/models/permissions';
import { FeatureToggleService } from '../../../services/feature-toggle.service';
import {
  moveBookmarks,
} from '../../actions/list.actions';
import {
  DEFAULT_BOOKMARKS_SEARCH,
  ListItem,
  ListItemEntity,
  ListType,
  ListWithItemsCount,
} from '../../models/list';
import { getOpenedList } from '../../reducers/list.reducer';
import { ListService } from '../../services/list.service';

@Component({
  selector: 'app-searchable-bookmarks-opened-list',
  templateUrl: './searchable-bookmarks-opened-list.component.html',
  styleUrls: ['./searchable-bookmarks-opened-list.component.scss']
})
export class SearchableBookmarksOpenedListComponent implements OnInit, OnDestroy {
  @Input() public lists: ListWithItemsCount[];
  @Input() public authorized: boolean;
  @Output() public closeList = new EventEmitter();
  public isAuthPatronFlagEnabled: boolean;
  public openedList: ListWithItemsCount = null;
  public items: ListItem[] = []
  public makeSearch = new BehaviorSubject<SearchRequestBody>(null);
  public itemsEntitySelected: ListItemEntity[] = [];
  public itemsSelectedCount: number = 0;
  public isAllSelected: boolean = false;
  public allListItemsLoaded = false;
  public showcaseIcon = faConveyorBeltAlt;
  public placeHoldsIcon = faCartShoppingFast;
  public sitePermissions = SitePermissions;
  public readonly hasViewShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_VIEW));
  public readonly hasCreateShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_CREATE));
  public readonly listType = ListType;
  public readonly arrowIconLeft = faAngleLeft;
  public readonly bulkFeatureKey = BulkFeatureKey.BOOKMARKS;
  public readonly CustomerFeature = CustomerFeature;
  public readonly bookmarkIcon = bookmarkIcon;

  private search: SearchRequestBody;
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly listService: ListService,
    private readonly featureToggleService: FeatureToggleService,
  ) {
  }

  ngOnInit() {
    this.isAuthPatronFlagEnabled = this.featureToggleService.getToggles()['DIS-30793_2024-04-27_auth_patron'];
    this.subscriptions.add(this.store.select(getOpenedList).subscribe(openedList => {
      if (openedList && openedList.items.length > 0) {
        openedList.items = [];
      }
      if (!this.openedList) {
        this.openedList = openedList;
      }
    }));
    this.subscriptions.add(this.makeSearch.subscribe(search => {
      if (!search) return;
      this.applySearch(search);
    }));
    this.makeSearch.next(DEFAULT_BOOKMARKS_SEARCH);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public loadMoreShowcaseItems() {
    this.makeSearch.next(this.search);
  }

  public onSearch(search: SearchRequestBody) {
    this.search = search;
    this.openedList.items = [];
    this.openedList.pagination.page = 0;
    this.makeSearch.next(this.search);
  }

  private applySearch(search: SearchRequestBody) {
    this.listService.searchList(this.openedList, search)
    .pipe(filter(Boolean)).subscribe(result => {
      this.openedList = result;
    });
  }

  public setAllItemsSelection(listId: string, selected: boolean): void {
    this.isAllSelected = selected;
    this.openedList.items.forEach(item => item.selected = this.isAllSelected);
    this.updateSelected();
  }

  public updateSelected() {
    this.itemsEntitySelected = this.openedList.items.filter((item) => item.selected).map((item) => item.entity);
    this.itemsSelectedCount = this.itemsEntitySelected.length;
  }

  public onToggleItem(listItem: ListItem): void {
    const item = this.openedList.items.find(item => item.id === listItem.id);
    if (item) {
      item.selected = !item.selected;
    }
    this.updateSelected();
  }

  public createShowcase(): void {
    const createdFrom = {id: this.openedList.id, name: this.openedList.name, type: CustomShowcaseCreatedFromType.list};
    this.store.dispatch(showForm({showcase: {createdFrom}}));
  }

  public viewShowcase(): void {
    this.store.dispatch(hideForm());
    this.store.dispatch(openShowcasesTab());
    this.store.dispatch(openShowcase({id: this.openedList.showcaseRef}));
  }

  public removeSelectedFromList(): void {
    const items = this.itemsEntitySelected.map((item) => item);
    this.store.dispatch(moveBookmarks({entities: items, fromListIds: [this.openedList.id]}));
  }

  public bulkPlaceHolds(event: Event) {
    event.stopPropagation();
    if (this.itemsEntitySelected.length) {
      const items = this.itemsEntitySelected.map((item) => item.sourceEntity);
      this.store.dispatch(tryToBulkHoldSelectedItems({bulkFeatureKey: this.bulkFeatureKey, items}));
    }
  }
}
