<app-bookshelf-template>
  <ng-template bookshelfTemplateTitle>
          <span data-automation-id="bookmarks-title" class="p-4" role="heading" aria-level="1">
            {{ 'bookmarksListsTitle' | translate }} | {{ lists.length }}
          </span>
  </ng-template>
  <!-- Sign In -->
  <ng-template *ngIf="!authorized" bookshelfTemplateSubheader>
    <div class="d-flex align-items-center" data-automation-id="bookmarks-subheader">
      <svg class="bookmark__subtitle__timer-sand-icon flex-shrink-0 mr-sm-3" viewBox="0 0 24 24">
        <path fill="currentColor"
              d="M6,2H18V8H18V8L14,12L18,16V16H18V22H6V16H6V16L10,12L6,8V8H6V2M16,16.5L12,12.5L8,16.5V20H16V16.5M12,11.5L16,7.5V4H8V7.5L12,11.5M10,6H14V6.75L12,8.75L10,6.75V6Z"/>
      </svg>
      <div class="mx-3">
        <p class="m-0" data-automation-id="bookmarks-subheader-disappear" translate>
          listYourBookmarksWillDisappear
        </p>
        <p class="m-0" data-automation-id="bookmarks-subheader-to-keep" translate>listToKeepSignIn</p>
      </div>
      <button class="ins-buttons button-primary flint ml-sm-3"
              data-automation-id="bookmarks-sign-in-button"
              (click)="logIn($event)"
              translate>
        signIn
      </button>
    </div>
  </ng-template>

  <!-- No Items -->
  <ng-template *ngIf="!authorized" bookshelfTemplateBody="no-ps">
    <div class="d-flex h-100 flex-column align-items-center justify-content-center">
      <div class="d-flex flex-column align-items-center mb-4">
        <fa-icon class="icon far fa-bookmark no-items__for-later__icon"
                 data-automation-id="bookmarks-for-later-icon"
                 [icon]="bookmarkIcon"></fa-icon>
        <span class="no-items__for-later__text"
              data-automation-id="bookmarks-for-later-text"
              translate>forLater</span>
      </div>
      <span data-automation-id="bookmarks-for-later-description"
            class="no-items__description text-center mx-4"
            translate>bookmarksBookmarkItemsThatYouWantToSeeAgain</span>
    </div>
  </ng-template>

  <ng-template *ngIf="authorized" bookshelfTemplateBody>
    <app-searchable-bookmarks-search-bar
      (onSearch)="onSearch($event)"
    ></app-searchable-bookmarks-search-bar>
    <app-searchable-bookmarks-results-preview
      [preview]="preview"
      (openList)="openAvailableList.emit()"
    ></app-searchable-bookmarks-results-preview>
    <ol class="d-flex flex-wrap justify-content-center justify-content-md-start mb-0"
        data-automation-id="bookmarks-lists-list"
        [attr.aria-label]="'bookmarksListsListTitle' | translate">
      <li *ngFor="let list of lists; trackBy: trackById"
          class="d-block m-2 m-md-4"
          data-automation-id="bookmarks-list-card">
        <app-list-card [list]="list" (onListOpened)="openList.emit(list)"></app-list-card>
      </li>
    </ol>
  </ng-template>
</app-bookshelf-template>
