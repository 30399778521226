import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { merge, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ClearResourceFacetsAction } from 'search/actions/facet.actions';
import { SearchResultsReset, SearchResultsResetLoadingAction } from 'search/actions/search.actions';
import { SearchRequestBody } from 'search/models/search-results';
import { toggleOpenedUserPanel } from 'user/actions/user-profile.actions';
import { UserPermission } from 'user/models/user';
import * as UserReducer from 'user/reducers/user.reducer';
import * as CustomShowcaseActions from '../../custom-showcase/actions/custom-showcase.actions';
import { CustomShowcaseCreatedFromType } from '../../custom-showcase/models/custom-showcase';
import { setScrollPosition, setSelectedResourceId } from '../../entity/actions/entity.actions';
import { getTopResultsCovers } from '../../entity/reducers/entity.reducer';
import { isAuthorizedInKeycloak, isStaffAuthorizedInKeycloak } from '../../keycloak/reducers/keycloak.reducer';
import { ShareOnSocialMediaService, SocialMediaType } from '../../services/share-on-social-media.service';
import { WindowRefService } from '../../services/window-ref.service';
import * as ShareItActions from '../../share-it/actions/share-it.actions';
import { ShareItShareEntityType } from '../../share-it/models/share-it';
import * as SavedSearchActions from '../actions/saved-search.actions';
import {
  SaveSearchModalContentComponent
} from '../components/save-search-modal-content/save-search-modal-content.component';
import { DeleteSavedSearchWithShowcaseError, SaveSearchError } from '../models/saved-search';
import { getShareType, ShareType } from '../reducers/saved-search.reducer';
import { SavedSearchService } from '../services/saved-search.service';
import { DiscoverV2BffService } from '../../elements-v2/service/discover-v2-bff.service';

@Injectable()
export class SavedSearchEffects {
  public createSavedSearch$ = createEffect(() => this.actions$.pipe(
    ofType(SavedSearchActions.createSavedSearch),
    map((action) => action.request),
    withLatestFrom(this.store.select(getTopResultsCovers)),
    switchMap(([request, coverConfigs]) => {
      const { name } = request;
      return this.savedSearchService.createSavedSearch(request)
      .pipe(
        map((savedSearch) => SavedSearchActions.createSavedSearchComplete({
          savedSearch: {
            ...savedSearch,
            name,
            coverConfigs: coverConfigs.map((config) => ({coverConfig: config}))
          }
        })),
        catchError((err: HttpErrorResponse) => {
          const error = SavedSearchEffects.extractErrorOrUnknown(err);
          return of(SavedSearchActions.createSavedSearchFailure({ error }));
        }),
      );
    }),
  ));

  public loadSavedSearches$ = createEffect(() => this.actions$.pipe(
    ofType(SavedSearchActions.loadSavedSearches),
    switchMap(() => {
      return this.savedSearchService.loadSavedSearches()
      .pipe(
        map((savedSearches) => SavedSearchActions.loadSavedSearchesComplete({ savedSearches })),
        catchError((err: HttpErrorResponse) => {
          const error = SavedSearchEffects.extractErrorOrUnknown(err);
          return of(SavedSearchActions.loadSavedSearchesFailure({ error }));
        }),
      );
    }),
  ));

  public updateSavedSearch$ = createEffect(() => this.actions$.pipe(
    ofType(SavedSearchActions.updateSavedSearch),
    map((action) => action.request),
    withLatestFrom(this.store.select(getTopResultsCovers)),
    switchMap(([request]) => {
      return this.savedSearchService.updateSavedSearch(request)
      .pipe(
        map(() => SavedSearchActions.updateSavedSearchComplete({ savedSearchUpdate: { ...request } })),
        catchError((err: HttpErrorResponse) => {
          const error = SavedSearchEffects.extractErrorOrUnknown(err);
          return of(SavedSearchActions.updateSavedSearchFailure({ id: request.id, error }));
        }),
      );
    }),
  ));

  // Before deleting a saved search, we should be certain that linked showcase (if any) is deleted first,
  // so we remove the latter and retrigger the effect. If showcase is not deleted, we can't proceed
  public deleteSavedSearch$ = createEffect(() => this.actions$.pipe(
    ofType(SavedSearchActions.deleteSavedSearch),
    concatMap((action) => of(action).pipe(
      withLatestFrom(this.store.select(UserReducer.doesUserHavePermission(UserPermission.SHOWCASES_PERSONAL_DELETE))),
    )),
    switchMap(([{savedSearch, showcaseRemoveResult}, canDeleteShowcase]) => {
      if (showcaseRemoveResult === 'error') {
        return of(SavedSearchActions.deleteSavedSearchFailure({id: savedSearch.id, error: {showcase: true}}));
      } else if (showcaseRemoveResult !== 'success') {
        if (savedSearch.showcaseRef) {
          if (canDeleteShowcase) {
            return of(CustomShowcaseActions.remove({
              id: savedSearch.showcaseRef,
              createdFrom: {id: savedSearch.id, name: savedSearch.name, type: CustomShowcaseCreatedFromType.savedSearch},
              actionOnSuccess: SavedSearchActions.deleteSavedSearch({savedSearch, showcaseRemoveResult: 'success'}),
              actionOnFailure: SavedSearchActions.deleteSavedSearch({savedSearch, showcaseRemoveResult: 'error'}),
            }));
          } else {
            return of(SavedSearchActions.deleteSavedSearchFailure({id: savedSearch.id, error: {showcase: true}}));
          }
        }
      }

      return this.savedSearchService.deleteSavedSearch(savedSearch)
      .pipe(
        map(() => SavedSearchActions.deleteSavedSearchComplete({id: savedSearch.id})),
        catchError((err: HttpErrorResponse) => {
          const error: DeleteSavedSearchWithShowcaseError = {savedSearch: SavedSearchEffects.extractErrorOrUnknown(err)};
          if (showcaseRemoveResult === 'success') {
            error.showcase = false;
          }

          return of(SavedSearchActions.deleteSavedSearchFailure({id: savedSearch.id, error}));
        }),
      );
    }),
  ));

  public shareSavedSearch$ = createEffect(() => (
    merge(
      this.actions$.pipe(ofType(SavedSearchActions.openEmailModal)),
      this.actions$.pipe(ofType(SavedSearchActions.copySavedSearch)),
      this.actions$.pipe(ofType(SavedSearchActions.shareOnFacebook)),
      this.actions$.pipe(ofType(SavedSearchActions.shareOnTwitter))
    ).pipe(
      map(({ id }) => SavedSearchActions.getSavedSearch({ id })),
    )
  ));

  public getSavedSearch$ = createEffect(() => this.actions$.pipe(
    ofType(SavedSearchActions.getSavedSearch),
    switchMap(({ id }) => {
      return this.savedSearchService.getSavedSearch(id)
      .pipe(
        map((response) => SavedSearchActions.getSavedSearchComplete({ response, id })),
        catchError((err: HttpErrorResponse) => {
          const error = SavedSearchEffects.extractErrorOrUnknown(err);
          return of(SavedSearchActions.getSavedSearchFailure({ id, error }));
        }),
      );
    }),
  ));

  public getSavedSearchComplete$ = createEffect(() => this.actions$.pipe(
    ofType(SavedSearchActions.getSavedSearchComplete),
    withLatestFrom(this.store.select(getShareType)),
    map(([{ id, title, image, response }, shareType]) => {
      const url = this.makeUrl(response.searchRequest);
      switch (shareType) {
        case ShareType.copy: {
          return SavedSearchActions.writeUrl({ id, url });
        }
        case ShareType.email: {
          return ShareItActions.openEmailEntityModal({
            defaultSubject: response.name,
            resource: {
              id,
              type: ShareItShareEntityType.SAVED_SEARCH,
              attributes: { url }
            }
          });
        }
        case ShareType.facebook: {
          const body = {
            title,
            description: '',
            id,
            url,
            image
          };

          this.discoverV2BffService.sharePage(body).subscribe(
            () => {
              this.shareOnSocialMediaService.shareOnSocialMedia(SocialMediaType.FACEBOOK, `${this.windowRef.origin()}/share/${id}`);
            }
          );
          return SavedSearchActions.redirectToSocialMediaSuccess();
        }
        case ShareType.twitter: {
          const body = {
            title,
            description: '',
            id,
            url,
            image
          };

          this.discoverV2BffService.sharePage(body).subscribe(
            () => {
              this.shareOnSocialMediaService.shareOnSocialMedia(SocialMediaType.TWITTER, `${this.windowRef.origin()}/share/${id}`);
            }
          );

          return SavedSearchActions.redirectToSocialMediaSuccess();
        }
      }
    })
  ));

  public emailEntityComplete$ = createEffect(() => this.actions$.pipe(
    ofType(ShareItActions.notifyEmailEntityModalClosed),
    filter(({ resource }) => resource.type === ShareItShareEntityType.SAVED_SEARCH),
    map(({ resource }) => SavedSearchActions.focusShareButton({ savedSearchId: resource.id })),
  ));

  public openSaveSearchModal$ = createEffect(() => this.actions$.pipe(
    ofType(SavedSearchActions.openSaveSearchModal),
    tap(() => {
      this.modal.open(
        SaveSearchModalContentComponent,
        {
          windowClass: 'inspire-custom-modal',
          ariaLabelledBy: 'save-search-modal-title',
        },
      );
    }),
  ), { dispatch: false });

  public tryToOpenSaveSearchModal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SavedSearchActions.tryToOpenSaveSearchModal),
      withLatestFrom(
        this.store.select(isAuthorizedInKeycloak),
        this.store.select(isStaffAuthorizedInKeycloak),
      ),
      switchMap(([_, isPatronAuthorized, isStaffAuthorized]) => {
        if (isPatronAuthorized || isStaffAuthorized) {
          return of(SavedSearchActions.openSaveSearchModal());
        }

        const tree = this.router.createUrlTree([], {
          relativeTo: this.route,
          queryParams: { openSaveSearchModal: 1 },
          queryParamsHandling: 'merge',
        }).toString();
        this.location.go(tree);

        return of(toggleOpenedUserPanel({isOpened: true}));
      }),
    );
  });

  public runSavedSearch$ = createEffect(() => this.actions$.pipe(
    ofType(SavedSearchActions.runSavedSearch),
    map((action) => action.savedSearchId),
    switchMap((savedSearchId) => {
      return this.savedSearchService.runSavedSearch(savedSearchId)
      .pipe(
        switchMap((response) => [
            setScrollPosition({scrollPosition: 0}),
            setSelectedResourceId({resourceId: ''}),
            new SearchResultsReset(),
            new ClearResourceFacetsAction(),
            SavedSearchActions.runSavedSearchResults(response),
        ]),
        catchError((err: HttpErrorResponse) => {
          const error = SavedSearchEffects.extractErrorOrUnknown(err);
          return of(SavedSearchActions.runSavedSearchFailure({ id: savedSearchId, error }));
        }),
      );
    }),
  ));

  public runSavedSearchNavigation$ = createEffect(() => this.actions$.pipe(
    ofType(SavedSearchActions.runSavedSearchResults),
    map((action) => action.searchObject),
    map((searchObject) => {
      this.router.navigate(['/search'], this.savedSearchService.makeQueryParams(searchObject));
    }),
  ), { dispatch: false });

  public savedSearchNotFound$ = createEffect(() => (
    merge(
      this.actions$.pipe(ofType(SavedSearchActions.getSavedSearchFailure)),
      this.actions$.pipe(ofType(SavedSearchActions.runSavedSearchFailure)),
    ).pipe(
      filter((action) => action.error.status === 404),
      map(() => SavedSearchActions.focusAvailableCardOrEmptyResultMessage()),
    )
  ));

  public handleRunSavedSearchFailure$ = createEffect(() => this.actions$.pipe(
    ofType(SavedSearchActions.runSavedSearchFailure),
    map(() => new SearchResultsResetLoadingAction()),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly savedSearchService: SavedSearchService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly store: Store,
    private readonly modal: NgbModal,
    private readonly windowRef: WindowRefService,
    private readonly shareOnSocialMediaService: ShareOnSocialMediaService,
    private discoverV2BffService: DiscoverV2BffService
  ) {
  }

  private static extractErrorOrUnknown(err: HttpErrorResponse): SaveSearchError {
    return (err.error?.status)
      ? err.error
      : {
        status: err.status,
        message: 'Unknown error',
      };
  }

  private makeUrl(searchRequest: SearchRequestBody): string {
    const queryParams = this.savedSearchService.makeQueryParams(
      this.savedSearchService.convertToSearchObject(searchRequest),
    );

    const tree = this.router.createUrlTree(['/search'], queryParams).toString();

    return `${this.windowRef.origin()}${tree}`;
  }
}
