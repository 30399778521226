import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faAngleLeft, faBookmark as bookmarkIcon } from '@fortawesome/pro-regular-svg-icons';
import { faCartShoppingFast, faConveyorBeltAlt } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngrx/store';
import { CustomerFeature } from 'app/customer-integration/customer-integration';
import { BehaviorSubject, combineLatest, filter, Observable, Subscription } from 'rxjs';
import { SearchRequestBody } from 'search/models/search-results';
import { tryToBulkHoldSelectedItems } from '../../../bulk-select/actions/bulk-select.actions';
import { BulkFeatureKey } from '../../../bulk-select/enums/bulk-feature-key';
import { SitePermissions } from '../../../permissions/models/permissions';
import { moveBookmarks } from '../../actions/list.actions';
import {
  DEFAULT_AVAILABLE_BOOKMARKS_SEARCH,
  ListItem,
  ListItemEntity,
  ListWithItemsCount,
  SearchAvailabilityFilter
} from '../../models/list';
import { ListService } from '../../services/list.service';

@Component({
  selector: 'app-searchable-bookmarks-available-list',
  templateUrl: './searchable-bookmarks-available-list.component.html',
  styleUrls: ['./searchable-bookmarks-available-list.component.scss']
})
export class SearchableBookmarksAvailableListComponent implements OnInit, OnDestroy {
  @Input() public lists: ListWithItemsCount[];
  @Input() public authorized: boolean;
  @Output() public closeList = new EventEmitter();
  public list: ListItem[] = [];
  public itemsEntitySelected: ListItemEntity[];
  public itemsSelectedCount: number = 0;
  public isAllSelected: boolean = false;
  public itemsCount: number = 0;
  public showcaseIcon = faConveyorBeltAlt;
  public placeHoldsIcon = faCartShoppingFast;
  public sitePermissions = SitePermissions;
  public availabilityFilter: SearchAvailabilityFilter = SearchAvailabilityFilter.Available;
  public makeSearch = new BehaviorSubject<SearchRequestBody>(null);
  public readonly arrowIconLeft = faAngleLeft;
  public readonly bulkFeatureKey = BulkFeatureKey.BOOKMARKS;
  public readonly CustomerFeature = CustomerFeature;
  public readonly bookmarkIcon = bookmarkIcon;

  private search: SearchRequestBody;
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly listService: ListService,
  ) {
  }

  ngOnInit() {
    this.lists.forEach(list => {
      this.itemsCount += list.itemsCount;
    });
    this.subscriptions.add(this.makeSearch.subscribe(search => {
      if (!search) return;
      this.applySearch(search);
    }));
    this.makeSearch.next(DEFAULT_AVAILABLE_BOOKMARKS_SEARCH);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public loadMoreShowcaseItems() {
    this.makeSearch.next(this.search);
  }

  public onSearch(search: SearchRequestBody) {
    this.search = search;
    this.list = [];
    this.lists.forEach(list => {
      list.items = [];
      list.pagination.page = 0;
    });
    this.makeSearch.next(search);
  }

  private applySearch(search: SearchRequestBody) {
    const obs: Observable<ListWithItemsCount>[] = [];
    for (const list of this.lists) {
      obs.push(this.listService.searchList(list, search));
    }
    return combineLatest(obs)
    .pipe(filter(Boolean)).subscribe(result => {
      this.lists = result;
      this.list = [];
      for (const list of this.lists) {
        this.list.push(...list.items);
      }
    });
  }

  public setAllItemsSelection(listId: string, selected: boolean): void {
    this.isAllSelected = selected;
    this.list.forEach(item => item.selected = this.isAllSelected);
    this.updateSelected();
  }

  public updateSelected() {
    this.itemsEntitySelected = this.list.filter((item) => item.selected).map((item) => item.entity);
    this.itemsSelectedCount = this.itemsEntitySelected.length;
  }

  public onToggleItem(listId: string, listItem: ListItem): void {
    const item = this.list.find(item => item.id === listItem.id);
    if (item) {
      item.selected = !item.selected;
    }
    this.updateSelected();
  }

  public removeSelectedFromList(): void {
    const items = this.itemsEntitySelected.map((item) => item);
    this.store.dispatch(moveBookmarks({entities: items, fromListIds: ['available-list']}));
  }

  public bulkPlaceHolds(event: Event) {
    event.stopPropagation();
    if (this.itemsEntitySelected.length) {
      const items = this.itemsEntitySelected.map((item) => item.sourceEntity);
      this.store.dispatch(tryToBulkHoldSelectedItems({bulkFeatureKey: this.bulkFeatureKey, items}));
    }
  }
}
